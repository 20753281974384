import React, { useEffect, useState } from 'react';

function BannerBox() {
    const [currentAd, setCurrentAd] = useState(0);
    const ads = [
        { src: `${process.env.PUBLIC_URL}/assets/images/main/rotator1.png`, href: '#', alt: 'Promotional Ad 1' },
        { src: `${process.env.PUBLIC_URL}/assets/images/main/rotator2.png`, href: '#', alt: 'Promotional Ad 2' },
        { src: `${process.env.PUBLIC_URL}/assets/images/main/rotator3.png`, href: '#', alt: 'Promotional Ad 3' },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentAd((prevAd) => (prevAd + 1) % ads.length);
        }, 7000);

        return () => clearInterval(interval);
    }, [ads.length]);

    return (
        <div id="sidebox">
            <a href={ads[currentAd].href} id="adLink1" target="_top" rel="noopener noreferrer">
                <img src={ads[currentAd].src} alt={ads[currentAd].alt} width="299" height="99" />
            </a>
        </div>
    );
}

export default BannerBox;
