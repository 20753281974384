// src/components/NotFound.js
import React from 'react';
import MainLayout from './MainLayout';
import { useTranslation } from 'react-i18next';

function NotFound() {
    const { t } = useTranslation();

    return (
        <MainLayout>

            {/* Center column */}
            <div id="center">
                <div className="contentarea_grey">Not Found</div>
                <div className="contentarea_mid">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/alts/newsie.png`} style={{ float: 'right' }} alt="News" />
                    <p>Page introuvable, merci de réessayer.</p>
                </div>
                <div className="contentarea_bottom"></div>
            </div>

            <br style={{ clear: 'both' }} />
        </MainLayout>
    );
}

export default NotFound;
