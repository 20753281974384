// src/pages/Goodies.js
import React from 'react';
import MainLayout from '../components/MainLayout';

function Goodies() {
    return (
        <MainLayout>

            {/* Center column */}
            <div id="center">
                <div className="contentarea_grey">Welcome to Goodies</div>
                <div className="contentarea_mid">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/alts/newsie.png`} style={{ float: 'right' }} alt="News" />
                    <p>Welcome content goes here...</p>
                </div>
                <div className="contentarea_bottom"></div>

                <div className="contentarea_purple">Events Timetable</div>
                <div className="contentarea_mid">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/alts/events.png`} style={{ float: 'right' }} alt="Events" />
                    <p>Event details go here...</p>
                </div>
                <div className="contentarea_bottom"></div>
            </div>

            <br style={{ clear: 'both' }} />
        </MainLayout>
    );
}

export default Goodies;
