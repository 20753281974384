import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: require('./locales/en/translation.json') },
            fr: { translation: require('./locales/fr/translation.json') },
            it: { translation: require('./locales/it/translation.json') },
            es: { translation: require('./locales/es/translation.json') },
        },
        fallbackLng: {
            default: ['en'], // Langue par défaut si aucune langue n'est détectée
        },
        supportedLngs: ['en', 'fr', 'it', 'es'],
        load: 'languageOnly', // Chargement de langue sans le code régional (ex: 'fr' au lieu de 'fr-FR')
        debug: true, // Activez le débogage pour voir la langue détectée dans la console (désactivez en production)

        interpolation: {
            escapeValue: false, // React protège déjà contre les failles XSS
        },

        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'], // Ordre de priorité de détection
            caches: ['localStorage', 'cookie'], // Stocke la langue préférée
            excludeCacheFor: ['cimode'], // Exclut 'cimode' du cache
            cookieMinutes: 10080, // Durée de conservation du cookie (7 jours)
        },
    });

export default i18n;
