// src/pages/Media.js
import React from 'react';
import MainLayout from '../components/MainLayout';

function Media() {
    const images = [
        'image1.png', 'image2.png', 'image3.png',
        'image4.gif', 'image5.png', 'image6.png',
        'image7.png', 'image8.png', 'image9.png'
    ];

    return (
        <MainLayout>
            {/* Center column */}
            <div id="center">
                <div className="contentarea_grey">Les Souvenirs</div>
                <div className="strobe_md stroboscope-container">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={`${process.env.PUBLIC_URL}/assets/images/media/${image}`}
                            alt={`Media ${index + 1}`}
                            className={`strobe-image strobe-${index % 3}`}
                        />
                    ))}
                </div>
                <div className="contentarea_bottom"></div>
            </div>

            <br style={{ clear: 'both' }} />
        </MainLayout>
    );
}

export default Media;
