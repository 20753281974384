// src/components/RadioPlayer.js
import React, { useState, useEffect } from 'react';
import audioSingleton from '../audioSingleton';

function RadioPlayer() {
    // Chargez l'état de lecture et le volume depuis localStorage
    const [isPlaying, setIsPlaying] = useState(() => {
        const savedIsPlaying = localStorage.getItem('isPlaying');
        return savedIsPlaying ? JSON.parse(savedIsPlaying) : false;
    });

    const [volume, setVolume] = useState(() => {
        const savedVolume = localStorage.getItem('volume');
        return savedVolume ? parseFloat(savedVolume) : 0.5;
    });

    useEffect(() => {
        // Initialiser l’audio avec le volume correct
        audioSingleton.setVolume(volume);

        // Si l’audio doit être en lecture, lancez la lecture
        if (isPlaying) {
            audioSingleton.play();
        }

        // Sauvegarder l'état de lecture et du volume dans localStorage
        localStorage.setItem('isPlaying', JSON.stringify(isPlaying));
        localStorage.setItem('volume', volume);
    }, [isPlaying, volume]);

    const togglePlay = () => {
        if (isPlaying) {
            audioSingleton.pause();
        } else {
            audioSingleton.play();
        }
        setIsPlaying(!isPlaying);
    };

    const adjustVolume = (change) => {
        let newVolume = Math.min(1, Math.max(0, volume + change));
        setVolume(newVolume);
        audioSingleton.setVolume(newVolume);
    };

    return (
        <div className="radio-player-container">
            <div className="radio-controls">
                <div className="play-disk-wrapper">
                    {isPlaying && (
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/holo/disk.svg`}
                            alt="Disk"
                            className="disk-icon rotating"
                        />
                    )}
                    <button onClick={togglePlay} className="play-button">
                        <img
                            src={
                                isPlaying
                                    ? `${process.env.PUBLIC_URL}/assets/images/holo/pause.svg`
                                    : `${process.env.PUBLIC_URL}/assets/images/holo/play.svg`
                            }
                            alt={isPlaying ? 'Pause' : 'Play'}
                            className="play-pause-icon"
                        />
                    </button>
                </div>
                <div className="volume-control">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/holo/moins.gif`}
                        alt="Decrease Volume"
                        className="volume-icon"
                        onClick={() => adjustVolume(-0.1)}
                    />
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.1"
                        value={volume}
                        onChange={(e) => {
                            const newVolume = parseFloat(e.target.value);
                            setVolume(newVolume);
                            audioSingleton.setVolume(newVolume);
                        }}
                    />
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/holo/plus.gif`}
                        alt="Increase Volume"
                        className="volume-icon"
                        onClick={() => adjustVolume(0.1)}
                    />
                </div>
            </div>
            <div className="bobba-logo-container">
                <img src={`${process.env.PUBLIC_URL}/assets/images/holo/bobba.gif`} alt="Bobba Logo" className="bobba-logo" />
            </div>
        </div>
    );
}

export default RadioPlayer;
