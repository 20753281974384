// src/audioSingleton.js
class AudioSingleton {
    constructor() {
        if (!AudioSingleton.instance) {
            this.audio = new Audio("https://streamer-04.rtl.fr/fun-1-44-64?listen=webCwsBCggNCQgLDQUGBAcGBg");
            this.audio.loop = true; // Boucle pour éviter l’arrêt après une seule lecture

            // Gestion des erreurs et redémarrage en cas de problème
            this.audio.addEventListener('error', () => this.handleAudioError());

            AudioSingleton.instance = this;
        }
        return AudioSingleton.instance;
    }

    play() {
        if (this.audio.paused) { // Vérifie si l'audio est déjà en lecture
            this.audio.play().catch(error => console.error("Erreur lors du démarrage du son :", error));
        }
    }

    pause() {
        this.audio.pause();
    }

    setVolume(volume) {
        this.audio.volume = volume;
    }

    get isPlaying() {
        return !this.audio.paused;
    }

    handleAudioError() {
        console.warn("Erreur audio détectée, tentative de redémarrage...");
        this.audio.load(); // Recharger le flux
        this.play(); // Essayer de relancer la lecture
    }
}

const instance = new AudioSingleton();
Object.freeze(instance);
export default instance;
