// src/pages/Radio.js
import React from 'react';
import MainLayout from '../components/MainLayout';
import { useTranslation } from 'react-i18next';

function Radio() {
    const { t } = useTranslation();
    return (
        <MainLayout>

            {/* Center column */}
            <div id="center">
                <div className="contentarea_grey">{t('pages.radio.welcome_message')}</div>
                <div className="contentarea_mid">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/holo/planet.gif`} style={{ float: 'right' }} alt="News" />
                    <p dangerouslySetInnerHTML={{ __html: t('pages.radio.content_placeholder') }} />
                </div>
                <div className="contentarea_bottom"></div>

                <div className="contentarea_purple">{t('pages.radio.events_timetable')}</div>
                <div className="contentarea_mid">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/holo/computer.gif`} style={{ float: 'right' }} alt="Events" />
                    <p dangerouslySetInnerHTML={{ __html: t('pages.radio.event_details_placeholder') }} />
                </div>
                <div className="contentarea_bottom"></div>
            </div>

            <br style={{ clear: 'both' }} />
        </MainLayout>
    );
}

export default Radio;
