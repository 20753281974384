import React from 'react';
import Navigation from './Navigation';
import BannerBox from './BannerBox';
import RadioPlayer from './RadioPlayer';
import { useTranslation } from 'react-i18next';

function MainLayout({ children }) {
    const { t } = useTranslation();
    return (
        <div id="wrapper">
            <header id="layout_top"></header>

            <div id="layout_mid">
                <Navigation />

                {/* Subnavigation Section */}
                <div id="dedicaces">
                    <div id="dedicaces-content">
                        <span className="dedicace"><b>Dédicace de CoolKid :</b> Gros big up à SwaggyGirl</span>
                        <span className="dedicace"><b>Dédicace de Legend78 :</b> Merci à MiniMaster pour sa beauté</span>
                        <span className="dedicace"><b>Dédicace de LovePeace :</b> Spéciale dédicace à FunLover pcq t con</span>
                        <span className="dedicace"><b>Dédicace de SwaggyGirl :</b> Un gros merci à PixelPro pour son rare</span>
                        <span className="dedicace"><b>Dédicace de PixelPro :</b> Dédicace à RetroQueen pour son avant-garde</span>
                        <span className="dedicace"><b>Dédicace de RetroQueen :</b> Merci à BestFriend pour le build de mon appart</span>
                        <span className="dedicace"><b>Dédicace de AdminMaster :</b> Merci à tous pour m'avoir élu guide !</span>
                        <span className="dedicace"><b>Dédicace de HabboFan :</b> Special thanks à tous mes potes de BG</span>
                        <span className="dedicace"><b>Dédicace de GamerGirl :</b> Bravo à toi, FunBoy pour ta place de GM</span>
                        <span className="dedicace"><b>Dédicace de NostalgiaFan :</b> Merci aux staffs pour les crédits !</span>

                        {/* Duplication pour un défilement fluide */}
                        <span className="dedicace"><b>Dédicace de CoolKid :</b> Gros big up à SwaggyGirl</span>
                        <span className="dedicace"><b>Dédicace de Legend78 :</b> Merci à MiniMaster pour sa beauté</span>
                        <span className="dedicace"><b>Dédicace de LovePeace :</b> Spéciale dédicace à FunLover pcq t con</span>
                        <span className="dedicace"><b>Dédicace de SwaggyGirl :</b> Un gros merci à PixelPro pour son rare</span>
                        <span className="dedicace"><b>Dédicace de PixelPro :</b> Dédicace à RetroQueen pour son avant-garde</span>
                        <span className="dedicace"><b>Dédicace de RetroQueen :</b> Merci à BestFriend pour le build de mon appart</span>
                        <span className="dedicace"><b>Dédicace de AdminMaster :</b> Merci à tous pour m'avoir élu guide !</span>
                        <span className="dedicace"><b>Dédicace de HabboFan :</b> Special thanks à tous mes potes de BG</span>
                        <span className="dedicace"><b>Dédicace de GamerGirl :</b> Bravo à toi, FunBoy pour ta place de GM</span>
                        <span className="dedicace"><b>Dédicace de NostalgiaFan :</b> Merci aux staffs pour les crédits !</span>
                    </div>

                </div>



                <div id="banner_box">
                    <div id="banner">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/main/inner.png`} alt="Inner content" />
                    </div>
                    <BannerBox />
                </div>

                <div id="contentarea">
                    <div id="left">
                        <div className="top_orange">{t('sidebar.radio_statistics')}</div>
                        <div className="box_mid">
                            <strong>{t('sidebar.dj_name')}</strong> DJ Klevarrrr! x<br />
                            <strong>{t('sidebar.listeners')}</strong> 350/355<br />
                            <strong>{t('sidebar.current_song')}</strong> Lady GaGa - Bad...
                        </div>
                        <div className="box_bottom"></div>

                        <div className="top_blue">🎶 FunRadio</div>
                        <div className="box_mid">
                            <RadioPlayer />
                        </div>
                        <div className="box_bottom"></div>

                        <div className="top_green">{t('sidebar.radio_player')}</div>
                        <div className="box_mid">{t('sidebar.omgggg')}</div>
                        <div className="box_bottom"></div>
                    </div>

                    <div id="center">
                        {children}
                    </div>

                    <div id="right">
                        <div className="top_pink">{t('right_panel.did_you_know')}</div>
                        <div className="box_mid">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/alts/cuppa.png`} style={{ float: 'right', marginTop: '-35px' }} alt="Trivia illustration" />
                            <p dangerouslySetInnerHTML={{ __html: t('right_panel.did_you_know_content') }} />
                        </div>
                        <div className="box_bottom"></div>

                        <div className="top_babyblue">{t('right_panel.quick_links')}</div>
                        <div className="box_mid">
                            <p>
                                # {t('right_panel.quick_links_list.staff_list')}<br />
                                # {t('right_panel.quick_links_list.job_applications')}<br />
                                # {t('right_panel.quick_links_list.view_timetable')}<br />
                                # {t('right_panel.quick_links_list.visit_forum')}
                            </p>
                        </div>
                        <div className="box_bottom"></div>

                        <div id="request_line"></div>
                        <div id="designed_coded"></div>
                    </div>
                </div>
            </div>

            <footer id="layout_bottom"></footer>
        </div>
    );
}

export default MainLayout;
