import React from 'react';
import { Link } from 'react-router-dom';
import i18n from '../i18n';

function Navigation() {
    const lang = i18n.language.split('-')[0]; // Prend uniquement la langue principale (fr au lieu de fr-FR)

    const navItems = [
        { path: '/', alt: 'Accueil', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/habbosoup.png` },
        { path: '/communaute', alt: 'Communauté', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/community.png` },
        { path: '/radio', alt: 'Radio', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/radio.png` },
        { path: '/media', alt: 'Media', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/media.png` },
        { path: '/recrutements', alt: 'Recrutements', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/jobs.png` },
        { path: '/goodies', alt: 'Goodies', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/goodies.png` },
        { path: '/guides', alt: 'Guides', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/guides.png` },
        { path: '/news', alt: 'News', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/events.png` },
        { path: '/contact', alt: 'Contact', src: `${process.env.PUBLIC_URL}/assets/images/buttons/${lang}/forum.png` },
    ];

    console.log("Current language folder:", lang);

    return (
        <div id="navigation">
            {navItems.map((item, index) => (
                <Link key={index} to={item.path}>
                    <img src={item.src} alt={item.alt} />
                </Link>
            ))}
        </div>
    );
}

export default Navigation;
