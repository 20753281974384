// src/pages/Recrutements.js
import React from 'react';
import MainLayout from '../components/MainLayout';
import { useTranslation } from 'react-i18next';

function Recrutements() {
    const { t } = useTranslation();
    return (
        <MainLayout>

            {/* Center column */}
            <div id="center">
                <div className="contentarea_grey">{t('pages.recrutements.welcome_message')}</div>
                <div className="contentarea_mid">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/holo/fusee.gif`} style={{ float: 'right' }} alt="Recrutement" />
                    <p dangerouslySetInnerHTML={{ __html: t('pages.recrutements.content_placeholder') }} />
                </div>
                <div className="contentarea_bottom"></div>

                <div className="contentarea_purple">{t('pages.recrutements.events_timetable')}</div>
                <div className="contentarea_mid">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/holo/oral.gif`} style={{ float: 'right' }} alt="Équipe" />
                    <p dangerouslySetInnerHTML={{ __html: t('pages.recrutements.event_details_placeholder') }} />
                </div>
                <div className="contentarea_bottom"></div>
            </div>

            <br style={{ clear: 'both' }} />
        </MainLayout >
    );
}

export default Recrutements;
