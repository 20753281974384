// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Accueil from './pages/Accueil';
import Communaute from './pages/Communaute';
import Radio from './pages/Radio';
import Media from './pages/Media';
import Recrutements from './pages/Recrutements';
import Goodies from './pages/Goodies';
import Guides from './pages/Guides';
import News from './pages/News';
import Contact from './pages/Contact';
import NotFound from './components/NotFound';
import Loader from './components/Loader';

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Affiche le loader au changement de route
    setLoading(true);

    // Simule un délai de chargement, puis masque le loader
    const timer = setTimeout(() => {
      setLoading(false);
    }, 800); // Ajustez la durée du délai en millisecondes (ex. 800ms)

    // Nettoyage du timer si le composant est démonté
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <Loader />}
      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/communaute" element={<Communaute />} />
        <Route path="/radio" element={<Radio />} />
        <Route path="/media" element={<Media />} />
        <Route path="/recrutements" element={<Recrutements />} />
        <Route path="/goodies" element={<Goodies />} />
        <Route path="/guides" element={<Guides />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

function RootApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default RootApp;
