// src/components/Loader.js
import React from 'react';

function Loader() {
    return (
        <div className="loader-overlay">
            <img src={`${process.env.PUBLIC_URL}/assets/images/holo/loader.gif`} alt="Loading" className="loader-image" />
            <p className="loader-text">Chargement de la page en cours...</p>
        </div>
    );
}

export default Loader;
